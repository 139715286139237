import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getPracticeData = createAsyncThunk(
  'practiceData/getPracticeData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`history/qbank-questions`, {
        params: {
          section_number: data.selectedSubtest.number,
          start_date: data.selectedFromDate,
          end_date: data.selectedToDate,
          reviewed:
            data.selectedQuestionState !== 'all'
              ? data.selectedQuestionState
              : undefined,
          page: data.questionsPageNumber,
          qbank_category_id: data.selectedCategory.id,
        },
      })
      return {
        data: res,
        isNew: data.questionsPageNumber === 1,
      }
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

export const getPracticeAttemptsData = createAsyncThunk(
  'practiceData/getPracticeAttemptsData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`history/qbank-attempts`, {
        params: {
          section_number: data.selectedSubtest.number,
          attempt_state: data.selectedAttemptState,
          start_date: data.selectedFromDate,
          end_date: data.selectedToDate,
          page: data.attemptPageNumber,
          qbank_category_id: data.selectedCategory.id,
        },
      })
      return {
        data: res,
        isNew: data.attemptPageNumber === 1,
      }
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
export const getPracticeReviewData = createAsyncThunk(
  'practiceData/getPracticeReviewData',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await axiosInterceptors.get(`history/review/${id}`)
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
export const deletePracticeAttempt = createAsyncThunk(
  'practiceData/deletePracticeAttempt',
  async ({ attemptId, acceptBypass }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.delete(`qbank/${attemptId}`, {
        data: { bypass_deletion_warning: acceptBypass },
      })
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const practiceDataSlice = createSlice({
  name: 'practiceData',
  initialState: {
    subtestIndex: null,
    practiceDeleteData: { isLoading: false, attemptId: null },
    selectedTab: 'ATTEMPTS',
    practiceQuestionData: {
      isLoading: false,
      seeMoreLoading: false,
      data: [],
      isLocked: false,
      error: '',
    },
    questionsPageNumber: 1,
    practiceAttemptData: {
      isLoading: false,
      seeMoreLoading: false,
      data: [],
      isLocked: false,
    },
    attemptPageNumber: 1,
    selectedPractice: { questionSelected: false, attemptSelected: false },
    selectedSubtest: { display_name: 'Select', id: null },
    selectedCategory: { name: 'Select', id: null },
    practiceReviewData: { isLoading: false, data: [] },
    selectedAttemptState: 'all',
    selectedQuestionState: 'all',
    selectedFromDate: null,
    selectedToDate: null,
    isKeepPracticingOpen: false,
    selectedPracticeType: { name: 'Same Question Type', isDiff: false },
    deleteAttemptId: null,
    acceptBypassDeletionWarning: false,
  },

  extraReducers: {
    [deletePracticeAttempt.pending]: (state, action) => {
      state.practiceDeleteData.isLoading = true
      state.practiceDeleteData.attemptId = action.meta.arg.attemptId
    },
    [deletePracticeAttempt.fulfilled]: (state, { payload, meta }) => {
      if (!state.acceptBypassDeletionWarning && meta.arg.acceptBypass) {
        state.acceptBypassDeletionWarning = meta.arg.acceptBypass
      }
      state.deleteAttemptId = null
      state.practiceDeleteData.isLoading = false
      state.practiceDeleteData.attemptId = null
      if (payload && payload.data && payload.data.attempt_id)
        state.practiceAttemptData.data.attempts =
          state.practiceAttemptData.data.attempts.map((attempt) => {
            if (attempt.question_set_attempt_id === payload.data.attempt_id) {
              return { ...attempt, isDisabled: true }
            } else {
              return attempt
            }
          })
      payload?.message &&
        toast.success(payload?.message, {
          duration: 4000,
          position: 'top-center',
          style: {
            backdropFilter: 'blur(4px)',
            marginTop: '20px',
            width: '350px',
          },
        })
    },
    [deletePracticeAttempt.rejected]: (state) => {
      state.practiceDeleteData.isLoading = false
      state.practiceDeleteData.attemptId = null
      toast.error('Something went wrong, please try again shortly', {
        duration: 3000,
        position: 'top-center',

        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '450px',
        },
      })
    },
    [getPracticeReviewData.pending]: (state) => {
      state.practiceReviewData.isLoading = true
    },
    [getPracticeReviewData.fulfilled]: (state, { payload }) => {
      state.practiceReviewData.isLoading = false
      state.practiceReviewData.data = payload?.data?.result_info
    },
    [getPracticeReviewData.rejected]: (state, { payload }) => {
      state.practiceReviewData.isLoading = false
      state.practiceReviewData.error = payload?.data?.message || ''
    },
    [getPracticeData.pending]: (state, action) => {
      if (action.meta.arg.questionsPageNumber === 1) {
        state.practiceQuestionData.isLoading = true
      } else {
        state.practiceQuestionData.seeMoreLoading = true
      }
    },
    [getPracticeData.fulfilled]: (state, action) => {
      const { data, isNew } = action.payload
      state.practiceQuestionData.isLocked = false
      if (isNew) {
        state.practiceQuestionData = data
        state.practiceQuestionData.isLoading = false
      } else {
        state.practiceQuestionData.data.questions = [
          ...state.practiceQuestionData.data.questions,
          ...data.data.questions,
        ]
        state.practiceQuestionData.seeMoreLoading = false
      }
    },
    [getPracticeData.rejected]: (state, action) => {
      if (action.meta.arg.questionsPageNumber === 1) {
        state.practiceQuestionData.isLoading = false
      } else {
        state.practiceQuestionData.seeMoreLoading = false
      }
      if (action.payload && action.payload.status === 403) {
        state.practiceQuestionData.isLocked = true
      }
    },
    [getPracticeAttemptsData.pending]: (state, action) => {
      if (action.meta.arg.attemptPageNumber === 1) {
        state.practiceAttemptData.isLoading = true
      } else {
        state.practiceAttemptData.seeMoreLoading = true
      }
    },
    [getPracticeAttemptsData.fulfilled]: (state, action) => {
      state.practiceAttemptData.isLocked = false
      const { data, isNew } = action.payload
      state.acceptBypassDeletionWarning = data?.data?.bypass_deletion_warning
      if (isNew) {
        state.practiceAttemptData = data
        state.practiceAttemptData.isLoading = false
      } else {
        state.practiceAttemptData.data.attempts = [
          ...state.practiceAttemptData.data.attempts,
          ...data.data.attempts,
        ]
        state.practiceAttemptData.seeMoreLoading = false
      }
    },
    [getPracticeAttemptsData.rejected]: (state, action) => {
      if (action?.meta.arg.attemptPageNumber === 1) {
        state.practiceAttemptData.isLoading = false
      } else {
        state.practiceAttemptData.seeMoreLoading = false
      }
      if (action?.payload?.status === 403) {
        state.practiceAttemptData.isLocked = true
      }
    },
  },
  reducers: {
    setDeleteAttemptId: (state, { payload }) => {
      state.deleteAttemptId = payload
    },
    clearPracticeReviewData: (state) => {
      state.isKeepPracticingOpen = false
      state.selectedPracticeType = { name: 'Same Question Type', isDiff: false }
    },
    setSelectedPracticeType: (state, { payload }) => {
      state.selectedPracticeType = payload
    },
    setKeepPracticingOpen: (state, { payload }) => {
      state.isKeepPracticingOpen = payload
    },
    updateQuestionPage: (state, { payload }) => {
      state.questionsPageNumber = payload + 1
    },
    updateAttemptPage: (state, { payload }) => {
      state.attemptPageNumber = payload + 1
    },
    updateQuestionSelected: (state, { payload }) => {
      state.selectedPractice = {
        questionSelected: payload,
        attemptSelected: payload,
      }
    },
    updateAttemptSelected: (state, { payload }) => {
      state.selectedPractice = {
        questionSelected: false,
        attemptSelected: payload,
      }
    },
    updateSelectedSubtest: (state, { payload }) => {
      state.selectedSubtest = payload
      if (state.questionsPageNumber !== 1) state.questionsPageNumber = 1
      if (state.attemptPageNumber !== 1) state.attemptPageNumber = 1
      state.selectedCategory = { name: 'Select', id: null }
    },
    updateSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload
      if (state.questionsPageNumber !== 1) state.questionsPageNumber = 1
      if (state.attemptPageNumber !== 1) state.attemptPageNumber = 1
    },
    setSelectedAttemptState: (state, { payload }) => {
      state.selectedAttemptState = payload
      if (state.questionsPageNumber !== 1) state.questionsPageNumber = 1
      if (state.attemptPageNumber !== 1) state.attemptPageNumber = 1
    },
    setSelectedQuestionState: (state, { payload }) => {
      state.selectedQuestionState = payload
      if (state.questionsPageNumber !== 1) state.questionsPageNumber = 1
      if (state.attemptPageNumber !== 1) state.attemptPageNumber = 1
    },
    resetFilter: (state) => {
      if (state.selectedSubtest && state.selectedSubtest.id)
        state.selectedSubtest = { display_name: 'Select', id: null }
      if (state.selectedCategory && state.selectedCategory.id)
        state.selectedCategory = { name: 'Select', id: null }
      if (state.selectedAttemptState) state.selectedAttemptState = 'all'
      if (state.selectedQuestionState) state.selectedQuestionState = 'all'
      if (state.selectedFromDate) state.selectedFromDate = null
      if (state.selectedToDate) state.selectedToDate = null
    },
    updateSelectedFromDate: (state, { payload }) => {
      state.selectedFromDate = payload
      if (state.questionsPageNumber !== 1) state.questionsPageNumber = 1
      if (state.attemptPageNumber !== 1) state.attemptPageNumber = 1
    },
    updateSelectedToDate: (state, { payload }) => {
      state.selectedToDate = payload
      if (state.questionsPageNumber !== 1) state.questionsPageNumber = 1
      if (state.attemptPageNumber !== 1) state.attemptPageNumber = 1
    },
    setSelectedTab: (state, { payload }) => {
      state.selectedTab = payload
    },
  },
})

export const {
  setDeleteAttemptId,
  clearPracticeReviewData,
  setSelectedPracticeType,
  setKeepPracticingOpen,
  updateQuestionPage,
  updateAttemptPage,
  updateQuestionSelected,
  updateAttemptSelected,
  updateSelectedSubtest,
  updateSelectedCategory,
  setSelectedAttemptState,
  setSelectedQuestionState,
  resetFilter,
  setSelectedTab,
  updateSelectedFromDate,
  updateSelectedToDate,
} = practiceDataSlice.actions

export default practiceDataSlice.reducer
