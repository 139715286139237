import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInterceptors } from 'utils/router/api'

export const getAvailableAttempt = createAsyncThunk(
  'resetAttempt/getAvailableAttempt',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.get(`resets/available-resets`)
      return response
    } catch (error) {
      return rejectWithValue(error.response)
    }
  },
)
export const getAvailableLegacyAttempt = createAsyncThunk(
  'resetAttempt/getAvailableLegacyAttempt',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptors.get(
        `resets/legacy-attempts-for-reset`,
        { headers: { notToRedirect404: true } },
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response)
    }
  },
)
export const resetAttempts = createAsyncThunk(
  'resetAttempt/resetAttempts',
  async ({ areas, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`resets/reset-attempts`, {
        areas,
      })
      onSuccess && onSuccess()
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
export const resetLegacyAttempts = createAsyncThunk(
  'resetAttempt/resetLegacyAttempts',
  async ({ attemptIds, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(`resets/reset-legacy-attempts`, {
        attempt_ids: attemptIds,
      })
      onSuccess && onSuccess(res?.data?.num_deleted)
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)
const resetAttemptSlice = createSlice({
  name: 'resetAttempt',
  initialState: {
    availableAttemptData: { isLoading: false, data: [] },
    availableLegacyAttemptData: { isLoading: false, data: [] },
    resetAttemptModel: false,
    resetLegacyAttemptModel: false,
    settingLoaderIndex: null,
    settingError: null,
    resetAttemptData: null,
    resetLegacyAttemptData: null,
    resetError: false,
    resetLegacyError: false,
    saveAvailableAttempt: [],
    saveAvailableLegacyAttempt: [],
  },
  extraReducers: {
    [getAvailableAttempt.pending]: (state, action) => {
      state.availableAttemptData.isLoading = true
    },
    [getAvailableAttempt.fulfilled]: (state, { payload }) => {
      state.availableAttemptData.data = payload.data
      state.availableAttemptData.isLoading = false
    },
    [getAvailableAttempt.rejected]: (state, action) => {
      state.availableAttemptData.isLoading = false
    },
    [getAvailableLegacyAttempt.pending]: (state, action) => {
      state.availableLegacyAttemptData.isLoading = true
    },
    [getAvailableLegacyAttempt.fulfilled]: (state, { payload }) => {
      state.availableLegacyAttemptData.data = payload.data
      state.availableLegacyAttemptData.isLoading = false
    },
    [getAvailableLegacyAttempt.rejected]: (state, action) => {
      state.availableLegacyAttemptData.isLoading = false
    },
    [resetAttempts.pending]: (state, action) => {
      state.resetAttemptLoading = true
      state.resetSuccessFully = false
      state.resetError = false
    },
    [resetAttempts.fulfilled]: (state, { payload }) => {
      state.resetAttemptData = payload.data
      state.resetSuccessFully = true
      state.resetAttemptLoading = false
    },
    [resetAttempts.rejected]: (state, action) => {
      state.resetAttemptLoading = false
      state.resetSuccessFully = false
      state.resetError = true
    },
    [resetLegacyAttempts.pending]: (state, action) => {
      state.resetLegacyAttemptLoading = true
      state.resetLegacySuccessFully = false
      state.resetLegacyError = false
    },
    [resetLegacyAttempts.fulfilled]: (state, { payload }) => {
      state.resetLegacySuccessFully = true
      state.resetLegacyAttemptLoading = false
    },
    [resetLegacyAttempts.rejected]: (state, action) => {
      state.resetLegacyAttemptLoading = false
      state.resetLegacySuccessFully = false
      state.resetLegacyError = true
    },
  },
  reducers: {
    showResetAttempt: (state, { payload }) => {
      state.resetAttemptModel = payload
    },
    showResetLegacyAttempt: (state, { payload }) => {
      state.resetLegacyAttemptModel = payload
    },
    saveResetAttemptData: (state, { payload }) => {
      state.saveAvailableAttempt = [...state.saveAvailableAttempt, payload]
    },
    saveResetLegacyAttemptData: (state, { payload }) => {
      state.saveAvailableLegacyAttempt = [
        ...state.saveAvailableLegacyAttempt,
        payload,
      ]
    },
    removeSaveResetAttemptData: (state, { payload }) => {
      state.saveAvailableAttempt = state.saveAvailableAttempt.filter(
        (data) => data !== payload,
      )
    },
    removeSaveResetLegacyAttemptData: (state, { payload }) => {
      state.saveAvailableLegacyAttempt =
        state.saveAvailableLegacyAttempt.filter((data) => data !== payload)
    },
    storeAvailableData: (state, { payload }) => {
      state.availableResets = payload
    },
    clearSaveResetAttemptData: (state, { payload }) => {
      state.saveAvailableAttempt = []
    },
    clearSaveResetLegacyAttemptData: (state, { payload }) => {
      state.saveAvailableLegacyAttempt = []
    },
    clearResetAttemptData: (state, { payload }) => {
      state.resetAttemptData = null
      state.availableResets = null
    },
    clearResetLegacyAttemptData: (state, { payload }) => {
      state.resetAttemptData = null
      state.availableLegacyResets = null
    },
    setResetError: (state, { payload }) => {
      state.resetError = payload
    },
    setResetLegacyError: (state, { payload }) => {
      state.resetLegacyError = payload
    },
  },
})

export const {
  showResetAttempt,
  showResetLegacyAttempt,
  saveResetAttemptData,
  saveResetLegacyAttemptData,
  removeSaveResetAttemptData,
  removeSaveResetLegacyAttemptData,
  clearSaveResetAttemptData,
  clearSaveResetLegacyAttemptData,
  clearResetAttemptData,
  clearResetLegacyAttemptData,
  storeAvailableData,
  setResetError,
  setResetLegacyError,
} = resetAttemptSlice.actions

export default resetAttemptSlice.reducer
