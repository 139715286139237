import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import moment from 'moment-timezone'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const getProgressData = createAsyncThunk(
  'dashboard/getProgressData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `performance-metrics/performance-over-time`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const getUserAccessArea = createAsyncThunk(
  'dashboard/getUserAccessArea',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/account/user-access-areas')
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const getTaskData = createAsyncThunk(
  'dashboard/getTaskData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/user-dashboard/get-tasks')
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const clearOverdueItems = createAsyncThunk(
  'dashboard/clearOverdueItems',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        '/user-dashboard/clear-overdue-tasks',
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const markAsReadAll = createAsyncThunk(
  'dashboard/markAsReadAll',
  async (_, { rejectWithValue }) => {
    try {
      const res = axiosInterceptors.post(
        `/notifications/mark-all-notification-viewed`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)
export const markAsReadNotification = createAsyncThunk(
  'dashboard/markAsReadNotification',
  async ({ notificationId }, { rejectWithValue }) => {
    try {
      const res = axiosInterceptors.put(
        `/notifications/${notificationId}/mark-notification-viewed`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)
export const getNotifications = createAsyncThunk(
  'dashboard/getNotifications',
  async ({}, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/notifications')
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)
export const getBadgesData = createAsyncThunk(
  'dashboard/getBadgesData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/user-dashboard/get-badges')
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const getJourneyData = createAsyncThunk(
  'dashboard/getJourneyData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/user-dashboard/journey')
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)
export const getSearchData = createAsyncThunk(
  'dashboard/getSearchData',
  async ({ term }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/search', {
        params: {
          term,
        },
      })
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)

export const getRecentActivity = createAsyncThunk(
  'dashboard/getRecentActivity',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/activity/recent')
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)

export const getContinueWhereData = createAsyncThunk(
  'dashboard/getContinueWhereData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        '/activity/continue-where-you-left-off',
        { params: { attempt_must_be_incomplete: 1 } },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)
export const getPromptsData = createAsyncThunk(
  'dashboard/getPromptsData',
  async ({ path }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/prompts', {
        params: {
          path: path || '/',
          from_app: 0,
        },
      })
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)

export const getUnusedBookings = createAsyncThunk(
  'dashboard/getUnusedBookings',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/bookings/get-unused-bookings-notifications`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)

export const getParticularSettings = createAsyncThunk(
  'dashboard/getParticularSettings',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/account/setting/test_date')
      return res
    } catch (err) {
      throw rejectWithValue(err?.response?.data)
    }
  },
)

export const savePromptView = createAsyncThunk(
  'dashboard/savePromptView',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(`/prompts/mark-viewed/${id}`)
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)

export const savePromptActioned = createAsyncThunk(
  'dashboard/savePromptActioned',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(`/prompts/mark-actioned/${id}`)
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)

export const setUcatTestDate = createAsyncThunk(
  'dashboard/setUcatTestDate',
  async ({ testDate }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put('/account/test-date', {
        test_date: testDate,
      })
      return res
    } catch (err) {
      throw rejectWithValue(err?.response?.data)
    }
  },
)

export const getTestDate = createAsyncThunk(
  'dashboard/getTestDate',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/account/setting/test_date')
      return res
    } catch (err) {
      throw rejectWithValue(err.response)
    }
  },
)

export const promptSaveSettingInfo = createAsyncThunk(
  'dashboard/promptSaveSettingInfo',
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(`account/settings`, {
        settings: [{ key: data.key, value: data.value }],
      })
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const promptMarkOnboardingAction = createAsyncThunk(
  'dashboard/promptMarkOnboardingAction',
  async ({ data, signal, onSuccess }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.put(
        `prompts/mark-onboarding-actioned/${data.id}`,
        {
          setting_key: data.key,
          setting_value: data.value,
          is_last: data.isLast,
        },
        { signal },
      )
      onSuccess && onSuccess()
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getMotivationalQuote = createAsyncThunk(
  'dashboard/getMotivationalQuote',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/motivational-quote')
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)

export const getHeatmap = createAsyncThunk(
  'dashboard/getHeatmap',
  async ({ months }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get('/activity/heatmap', {
        params: { months },
      })
      return res
    } catch (err) {
      throw rejectWithValue(err.response?.data)
    }
  },
)

export const getPlatformStatus = createAsyncThunk(
  'dashboard/getPlatformStatus',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `${process.env.REACT_APP_PROFILE_API_URL}/olp-shop-services/student/get-platform-status`,
      )
      return res
    } catch (err) {
      throw rejectWithValue(err.response.data)
    }
  },
)

export const getDownTimeNotice = createAsyncThunk(
  'dashboard/getDownTimeNotice',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`/check-downtime-notices`)
      return res
    } catch (err) {
      throw rejectWithValue(err?.response?.data)
    }
  },
)

export const saveCelebrationModal = createAsyncThunk(
  'dashboard/saveCelebrationModal',
  async ({ id, userPressedAction }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `celebration/${id}/mark-viewed`,
        {
          user_pressed_action: userPressedAction,
        },
      )
      return res
    } catch (error) {
      throw rejectWithValue(error.response)
    }
  },
)

const localSidebar = localStorage.getItem('non-review-page-sidebar')
const userLocalData = JSON.parse(localStorage.getItem('user-data'))
export const dashboardSlice = createSlice({
  name: 'counter',
  initialState: {
    downTimeCalled: false,
    downTimeNotice: null,
    sidebarExpanded: localSidebar === 'false' ? false : true,
    showSearchBar: false,
    showNavigation: false,
    selectedTheme: 'Light',
    searchText: null,
    globalSearchDebounce: null,
    selectedGraphTab: 'VR',
    userBadgesData: { isLoading: false, data: [], isLocked: false },
    userJourneyData: { isLoading: false, data: [], isLocked: false },
    searchData: { isLoading: false, data: [] },
    recentActivity: { isLoading: false, data: [] },
    progressData: { isLoading: false, data: [], isLocked: false },
    taskData: { isLoading: false, data: [], isLocked: false },
    notificationsData: { isLoading: false, data: [] },
    markAsReadNotifications: { isLoading: false, isRejected: false },
    showBadge: false,
    continueWhereData: { isLoading: false, data: [] },
    openMenu: false,
    deleteOverdueTasks: false,
    overdueTaskLoading: false,
    promptsData: {
      isLoading: false,
      data: [],
      timeRemaining: 0,
      promptType: '',
      currentPrompt: '',
    },
    settingError: null,
    savePromptActionLoading: false,
    settingsPromptUpdateLoading: null,
    showPromptsModal: false,
    promptSettingsData: { isLoading: false, data: [], isError: false },
    settingTestDate: null,
    userAccessArea: {
      isLoading: false,
      data: userLocalData?.access_areas || [],
      isRejected: false,
    },
    motivationalQuoteData: { isLoading: false, data: null, isLocked: false },
    heatMapData: { isLoading: false, data: null, isLocked: false },
    showHeatMapModal: false,
    platformStatus: {
      isLoading: false,
      data: null,
    },
    unusedBookings: { isLoading: false, data: null },
    hasChatbotAccess: false,
    finishOnboardingMessage: null,
  },
  extraReducers: {
    [getDownTimeNotice.pending]: (state) => {
      localStorage.setItem('check-downtime-notices', moment().toISOString())
      state.downTimeCalled = true
    },
    [getDownTimeNotice.fulfilled]: (state, { payload }) => {
      state.downTimeNotice = payload?.data
    },
    [getDownTimeNotice.rejected]: (state, { payload }) => {},
    [getUnusedBookings.pending]: (state) => {
      state.unusedBookings.isLoading = true
    },
    [getUnusedBookings.fulfilled]: (state, { payload }) => {
      state.unusedBookings.data = payload.data
      state.unusedBookings.isLoading = false
    },
    [getUnusedBookings.rejected]: (state, { payload }) => {
      state.unusedBookings.isLoading = false
    },
    [getPlatformStatus.pending]: (state) => {
      state.platformStatus.isLoading = true
    },
    [getPlatformStatus.fulfilled]: (state, { payload }) => {
      state.platformStatus.data = payload?.data
    },
    [getPlatformStatus.rejected]: (state, { payload }) => {
      state.platformStatus.isLoading = false
    },
    [getTestDate.fulfilled]: (state, { payload }) => {
      state.settingTestDate = payload.data
    },
    [getHeatmap.pending]: (state) => {
      state.heatMapData.isLoading = true
      state.heatMapData.isLocked = false
    },
    [getHeatmap.fulfilled]: (state, { payload }) => {
      state.heatMapData.isLoading = false
      state.heatMapData.data = payload.data
      state.heatMapData.isLocked = false
    },
    [getHeatmap.rejected]: (state, { payload }) => {
      state.heatMapData.isLoading = false
      if (payload && payload?.status === 403) {
        state.heatMapData.isLocked = true
      }
    },
    [getMotivationalQuote.pending]: (state) => {
      state.motivationalQuoteData.isLoading = true
    },
    [getMotivationalQuote.fulfilled]: (state, { payload }) => {
      state.motivationalQuoteData.isLoading = false
      state.motivationalQuoteData.data = payload.data
      state.motivationalQuoteData.isLocked = false
    },
    [getMotivationalQuote.rejected]: (state, { payload }) => {
      state.motivationalQuoteData.isLoading = false
      if (payload && payload?.status === 403) {
        state.motivationalQuoteData.isLocked = true
      }
    },
    [setUcatTestDate.pending]: (state) => {
      state.promptSettingsData.isLoading = true
    },
    [setUcatTestDate.fulfilled]: (state, { meta }) => {
      state.promptSettingsData.isLoading = false
      state.showPromptsModal = false
      toast.success(`UCAT test date set on ${meta?.arg?.testDate}`, {
        duration: 4000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
    },
    [setUcatTestDate.rejected]: (state, { payload }) => {
      toast.error(payload?.message, {
        duration: 3000,
        style: { maxWidth: 'auto' },
      })
      state.promptSettingsData.isLoading = false
    },
    [getParticularSettings.pending]: (state) => {
      state.promptSettingsData.isLoading = true
      state.promptSettingsData.isError = false
    },
    [getParticularSettings.fulfilled]: (state, { payload }) => {
      state.promptSettingsData.isLoading = false
      state.promptSettingsData.isError = false
      if (payload.data) {
        state.promptSettingsData.data = payload.data
      }
    },
    [getParticularSettings.rejected]: (state, { payload }) => {
      state.promptSettingsData.isLoading = false
      state.promptSettingsData.isError = true
      toast.error(payload?.message, {
        duration: 3000,
        style: { maxWidth: 'auto' },
      })
    },

    // [savePromptView.fulfilled]: (state, action) => {
    //   state.promptsData.data = state.promptsData.data.filter(
    //     (data) => data?.id !== action.meta.arg.id,
    //   )
    // },
    [savePromptActioned.pending]: (state) => {
      state.savePromptActionLoading = true
    },
    [savePromptActioned.fulfilled]: (state) => {
      state.savePromptActionLoading = false
      const currentPromptIndex = findIndex(state.promptsData?.data, {
        id: state.promptsData?.currentPrompt?.id,
      })
      const nextPrompt = state.promptsData.data[currentPromptIndex + 1]
      if (nextPrompt) {
        state.promptsData.promptType = nextPrompt.prompt_type
        state.promptsData.currentPrompt = nextPrompt
      } else {
        state.promptsData.promptType = ''
        state.promptsData.currentPrompt = ''
        state.promptsData.data = []
        state.showPromptsModal = false
      }
    },
    [savePromptActioned.rejected]: (state) => {
      state.savePromptActionLoading = false
      const currentPromptIndex = findIndex(state.promptsData?.data, {
        id: state.promptsData?.currentPrompt?.id,
      })
      const nextPrompt = state.promptsData.data[currentPromptIndex + 1]
      if (nextPrompt) {
        state.promptsData.promptType = nextPrompt.prompt_type
        state.promptsData.currentPrompt = nextPrompt
      } else {
        state.promptsData.promptType = ''
        state.promptsData.currentPrompt = ''
        state.promptsData.data = []
        state.showPromptsModal = false
      }
    },
    [getPromptsData.pending]: (state) => {
      state.promptsData = {
        isLoading: true,
        data: [],
        timeRemaining: 0,
        promptType: '',
        currentPrompt: '',
      }
    },
    [getPromptsData.fulfilled]: (state, { payload }) => {
      state.promptsData.isLoading = false
      if (payload?.data?.required_onboarding_prompt_displays?.length > 0) {
        const sortedPrompts =
          payload?.data?.required_onboarding_prompt_displays?.sort(
            (a, b) => a.priority < b.priority,
          )
        state.promptsData.timeRemaining = 900
        state.promptsData.data =
          payload?.data?.required_onboarding_prompt_displays
        state.promptsData.currentPrompt = sortedPrompts[0]
        state.promptsData.promptType = sortedPrompts[0]?.prompt_type
      } else if (payload?.data?.required_prompt_displays?.length > 0) {
        const sortedPrompts = payload?.data?.required_prompt_displays?.sort(
          (a, b) => a.priority < b.priority,
        )
        state.promptsData.timeRemaining = 900
        state.promptsData.data = payload?.data?.required_prompt_displays

        state.promptsData.currentPrompt = sortedPrompts[0]
        state.promptsData.promptType = sortedPrompts[0]?.prompt_type
      }
      state.promptsData.required_onboarding_prompt_displays =
        payload?.data?.required_onboarding_prompt_displays

      state.promptsData.required_prompts =
        payload?.data?.required_prompt_displays
    },
    [getPromptsData.rejected]: (state) => {
      state.promptsData.isLoading = false
    },

    [promptMarkOnboardingAction.pending]: (state, { payload, meta }) => {
      const { subSettingIndex } = meta.arg.data
      state.settingsPromptUpdateLoading = subSettingIndex
    },
    [promptMarkOnboardingAction.fulfilled]: (state, { payload, meta }) => {
      state.settingsPromptUpdateLoading = null
      if (state.promptsData?.currentPrompt?.prompt_data?.setting) {
        state.promptsData.currentPrompt.prompt_data.setting =
          payload?.data?.setting
      }
      const currentPromptIndex = findIndex(state.promptsData?.data, {
        id: state.promptsData?.currentPrompt?.id,
      })
      const nextPrompt = state.promptsData.data[currentPromptIndex + 1]
      if (nextPrompt) {
        state.promptsData.promptType = nextPrompt.prompt_type
        state.promptsData.currentPrompt = nextPrompt
      }
      state.finishOnboardingMessage = payload?.data?.message || null
    },
    [promptMarkOnboardingAction.rejected]: (state, { payload }) => {
      state.settingsPromptUpdateLoading = null
      state.promptsData.promptType = ''
      state.promptsData.currentPrompt = ''
      state.promptsData.data = []
      state.showPromptsModal = false
    },

    [promptSaveSettingInfo.pending]: (state, { meta }) => {
      const { subSettingIndex } = meta.arg.data
      state.settingsPromptUpdateLoading = subSettingIndex
    },
    [promptSaveSettingInfo.fulfilled]: (state, { payload, meta }) => {
      const { subSettingIndex } = meta.arg.data
      state.settingsPromptUpdateLoading = null
      state.settingError = null
      if (state.promptsData?.currentPrompt?.prompt_data?.settings?.length > 0) {
        state.promptsData.currentPrompt.prompt_data.settings[
          subSettingIndex
        ].has_student_value_set = payload.data?.updates[0]?.updated
        state.promptsData.currentPrompt.prompt_data.settings[
          subSettingIndex
        ].student_value = payload.data?.updates[0]?.new_value
      }
    },
    [promptSaveSettingInfo.rejected]: (state, action) => {
      state.settingsPromptUpdateLoading = null
      state.settingError = {
        index: action.meta.arg.data.settingIndex,
        subIndex: action.meta.arg.data.subSettingIndex,
        error:
          (action.payload?.errors &&
            action.payload?.errors['settings.0']?.length &&
            action.payload?.errors['settings.0'][0]) ||
          action?.payload?.message,
      }
    },
    [clearOverdueItems.pending]: (state) => {
      state.overdueTaskLoading = true
    },
    [clearOverdueItems.fulfilled]: (state) => {
      state.overdueTaskLoading = false
      if (state?.taskData?.data?.user_overdue_tasks) {
        state.taskData.data.user_overdue_tasks = []
      }
    },
    [clearOverdueItems.rejected]: (state) => {
      state.overdueTaskLoading = false
    },
    [getContinueWhereData.pending]: (state) => {
      state.continueWhereData.isLoading = true
    },
    [getContinueWhereData.fulfilled]: (state, { payload }) => {
      state.continueWhereData.isLoading = false
      state.continueWhereData.data = payload.data
    },
    [getContinueWhereData.rejected]: (state) => {
      state.continueWhereData.isLoading = false
    },
    [markAsReadNotification.fulfilled]: (state, action) => {
      const updateDataIndex =
        state.notificationsData.data.notifications.findIndex(
          (data) => data.id === action.meta.arg.notificationId,
        )
      if (updateDataIndex)
        state.notificationsData.data.notifications[updateDataIndex] =
          action.payload.data.notification
    },
    [markAsReadAll.pending]: (state) => {
      state.markAsReadNotifications.isLoading = true
      state.markAsReadNotifications.isRejected = false
    },
    [markAsReadAll.fulfilled]: (state, { payload }) => {
      if (state?.notificationsData?.data?.notifications?.length > 0) {
        state.notificationsData.data.notifications =
          state?.notificationsData?.data?.notifications?.map((notification) => {
            if (!notification?.read_at) {
              return {
                ...notification,
                read_at: new Date(),
              }
            } else {
              return notification
            }
          })
      }
      state.markAsReadNotifications.isRejected = false
      state.markAsReadNotifications.isLoading = false
    },
    [markAsReadAll.rejected]: (state) => {
      state.markAsReadNotifications.isRejected = true
      state.markAsReadNotifications.isLoading = false
    },
    [getNotifications.pending]: (state) => {
      state.notificationsData.isLoading = true
      state.notificationsData.isRejected = false
      state.notificationsData.data = []
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.notificationsData.data = payload.data
      state.notificationsData.isLoading = false
      const date = new Date()
      localStorage.setItem('notifications-date', date)
    },
    [getNotifications.rejected]: (state) => {
      state.notificationsData.isRejected = true
      state.notificationsData.isLoading = false
    },
    [getSearchData.pending]: (state) => {
      state.searchData.isLoading = true
      state.searchData.isRejected = false
      state.searchData.data = []
    },
    [getSearchData.fulfilled]: (state, { payload }) => {
      state.searchData.data = payload.data
      state.searchData.isLoading = false
      state.hasChatbotAccess = payload?.data?.has_chatbot_access
    },
    [getSearchData.rejected]: (state) => {
      state.searchData.isRejected = true
      state.searchData.isLoading = false
    },
    [getRecentActivity.pending]: (state) => {
      state.recentActivity.isLoading = true
    },
    [getRecentActivity.fulfilled]: (state, { payload }) => {
      const date = new Date()
      localStorage.setItem('recent-activity-date', date)
      localStorage.setItem('recent-activity-data', JSON.stringify(payload.data))
      state.recentActivity.data = payload.data
      state.recentActivity.isLoading = false
    },
    [getRecentActivity.rejected]: (state) => {
      state.recentActivity.isLoading = false
    },
    [getProgressData.pending]: (state) => {
      state.progressData.isLoading = true
    },
    [getProgressData.fulfilled]: (state, { payload }) => {
      state.progressData.data = payload.data
      state.progressData.isLoading = false
      state.progressData.isLocked = false
    },
    [getProgressData.rejected]: (state, { payload }) => {
      state.progressData.isLoading = false
      if (payload && payload?.status === 403) {
        state.progressData.isLocked = true
      }
    },
    [getTaskData.pending]: (state) => {
      state.taskData.isLoading = true
    },
    [getTaskData.rejected]: (state, { payload }) => {
      state.taskData.isLoading = false
      if (payload && payload?.status === 403) {
        state.taskData.isLocked = true
      }
    },
    [getTaskData.fulfilled]: (state, { payload }) => {
      state.taskData.data = payload.data
      state.taskData.isLoading = false
      state.taskData.isLocked = false
    },
    [getJourneyData.pending]: (state) => {
      state.userJourneyData.isLoading = true
    },
    [getJourneyData.fulfilled]: (state, { payload }) => {
      state.userJourneyData.data = payload.data
      state.userJourneyData.isLoading = false
    },
    [getJourneyData.rejected]: (state, { payload }) => {
      state.userJourneyData.isLoading = false
      if (payload && payload?.status === 403) {
        state.userJourneyData.isLocked = true
      }
    },
    [getBadgesData.pending]: (state) => {
      state.userBadgesData.isLoading = true
    },
    [getBadgesData.rejected]: (state, { payload }) => {
      state.userBadgesData.isLoading = false
      if (payload && payload?.status === 403) {
        state.userBadgesData.isLocked = true
      }
    },
    [getBadgesData.fulfilled]: (state, { payload }) => {
      state.userBadgesData.data = payload.data
      state.userBadgesData.isLoading = false
    },
    [getUserAccessArea.pending]: (state) => {
      state.userAccessArea.isLoading = true
    },
    [getUserAccessArea.rejected]: (state, { payload }) => {
      state.userAccessArea.isLoading = false
      state.userAccessArea.isRejected = true
    },
    [getUserAccessArea.fulfilled]: (state, { payload }) => {
      if (payload.data?.areas?.length > 0) {
        state.userAccessArea.data = payload.data?.areas
      }
      state.userAccessArea.isLoading = false
    },
  },
  reducers: {
    clearFinishOnboarding: (state) => {
      state.finishOnboardingMessage = null
    },
    updateRequiredPromptDisplays: (state, { payload }) => {
      const sortedPrompts = payload
        ?.slice()
        ?.sort((a, b) => a.priority < b.priority)
      state.promptsData.timeRemaining = 900
      state.promptsData.data = payload
      state.promptsData.isFromCommonPrompt = true
      state.promptsData.currentPrompt = sortedPrompts[0]
      state.promptsData.promptType = sortedPrompts[0]?.prompt_type
    },
    clearPlatformStatus: (state) => {
      state.platformStatus = {
        isLoading: false,
        data: null,
      }
    },
    setShowHeatMapModal: (state, { payload }) => {
      state.showHeatMapModal = payload
    },
    clearWorkshopsData: (state) => {
      state.isWorkshopTour = false
    },
    setpromptType: (state, { payload }) => {
      const newItem =
        (state.promptsData?.required_prompts?.length > 0 &&
          state.promptsData?.required_prompts?.filter(
            (item) => item !== payload.promptType,
          )) ||
        []
      if (newItem && newItem?.length > 0) {
        state.promptsData.promptType = newItem[0]
        state.promptsData.data = newItem
        state.promptsData.required_prompts = newItem
      } else {
        state.promptsData.promptType = ''
        state.promptsData.isFromCommonPrompt = false
        state.promptsData.data = []
      }
    },
    setOpenMenu: (state, { payload }) => {
      state.openMenu = payload
    },
    toggleShowBadgeDialog: (state) => {
      state.showBadge = !state.showBadge
    },
    toggleReviewSidebar: (state) => {
      //For review page sidebar exapanded
      localStorage.setItem('review-page-sidebar', !state.sidebarExpanded)
      state.sidebarExpanded = !state.sidebarExpanded
    },
    toggleSidebar: (state) => {
      //For non review page sidebar exapanded
      localStorage.setItem('non-review-page-sidebar', !state.sidebarExpanded)
      state.sidebarExpanded = !state.sidebarExpanded
    },
    openSidebar: (state, { payload }) => {
      state.sidebarExpanded = payload
    },
    toggleSearchBar: (state) => {
      state.showSearchBar = !state.showSearchBar
    },
    toggleNavigation: (state) => {
      state.showNavigation = !state.showNavigation
    },
    toggleTheme: (state, { payload }) => {
      state.selectedTheme = payload
    },
    updateSearchText: (state, { payload }) => {
      state.searchText = payload || null
      if (payload && payload.length >= 3) state.searchData.isLoading = true
    },
    updateSearchDebounce: (state, { payload }) => {
      state.globalSearchDebounce = payload
    },
    updateSelectedTab: (state, { payload }) => {
      state.selectedGraphTab = payload
    },
    setDeleteOverdueTasks: (state, { payload }) => {
      state.deleteOverdueTasks = payload
    },
    setShowPromptsModal: (state, { payload }) => {
      state.showPromptsModal = payload
    },
    setTimeRemaining: (state) => {
      state.promptsData.timeRemaining -= 1
    },
    resetTimeRemaining: (state) => {
      if (state.promptsData?.timeRemaining) {
        state.promptsData.timeRemaining = 0
      }
    },
    setPromptSettingsDate: (state, { payload }) => {
      if (state.promptSettingsData.data) {
        state.promptSettingsData.data.current_value = payload
      }
    },
    removeError: (state) => {
      state.settingError = null
    },
  },
})

export const {
  clearFinishOnboarding,
  updateRequiredPromptDisplays,
  clearPlatformStatus,
  setShowHeatMapModal,
  clearWorkshopsData,
  setpromptType,
  resetTimeRemaining,
  openSidebar,
  setTimeRemaining,
  setOpenMenu,
  toggleReviewSidebar,
  toggleSidebar,
  toggleSearchBar,
  toggleNavigation,
  toggleTheme,
  updateSearchDebounce,
  updateSearchText,
  updateSelectedTab,
  toggleShowBadgeDialog,
  setDeleteOverdueTasks,
  setShowPromptsModal,
  setPromptSettingsDate,
  removeError,
} = dashboardSlice.actions

export default dashboardSlice.reducer
