import { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { Shape } from 'components/icons'

const AchievementPopover = ({
  show,
  onClose,
  achievementData,
  setUserPressedAction,
}) => {
  const [progress, setProgress] = useState(100)

  useEffect(() => {
    let progressTimer
    const interval = 30
    const totalDuration = 4000
    const decrementAmount = (interval / totalDuration) * 100

    if (show) {
      progressTimer = setInterval(() => {
        setProgress((prev) => Math.max(prev - decrementAmount, 0))
      }, interval)

      const timer = setTimeout(() => {
        onClose()
        setProgress(100)
      }, totalDuration)

      return () => {
        clearTimeout(timer)
        clearInterval(progressTimer)
      }
    }
  }, [show, onClose])

  const handleActionClick = () => {
    setUserPressedAction(true)
  }
  const messageClasses = 'text-sm text-gray-700'

  return (
    <>
      <Transition
        show={show}
        enter="transform transition ease-out duration-500"
        enterFrom="translate-x-full opacity-0"
        enterTo="translate-x-0 opacity-100"
        leave="transform transition ease-in duration-500"
        leaveFrom="translate-x-0 opacity-100"
        leaveTo="translate-x-full opacity-0"
        className="fixed top-4 right-4 z-50 bg-white shadow-xl border border-gray-300 rounded-lg overflow-hidden flex flex-col items-center"
      >
        <div className="relative">
          <div className="p-4">
            <div className="flex items-center gap-3">
              <span className="rounded-full border-2 border-red-500 bg-red-50 h-8 w-8 flex items-center justify-center p-2">
                <Shape className="text-red-500 h-6 w-6" />
              </span>
              <p className={messageClasses}>{achievementData?.message}</p>
            </div>
            {achievementData?.action_url ? (
              <div className="flex justify-end mt-2">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={achievementData?.action_url}
                  onClick={handleActionClick}
                  className="text-xs bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600"
                >
                  {achievementData?.action_title}
                </a>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="w-full bg-white h-1 relative">
            <div
              className="h-full transition-all duration-100 ease-linear bg-red-500"
              style={{ width: `${100 - progress}%` }}
            ></div>
          </div>
        </div>{' '}
      </Transition>
    </>
  )
}

export default AchievementPopover
