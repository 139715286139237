import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { axiosInterceptors } from 'utils/router/api'

export const startBondTrainerAttempt = createAsyncThunk(
  'bondTrainer/bondTrainer',
  async ({ quizId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.post(
        `/trainer/bond-interview/start-attempt`,
        { bond_quiz_id: quizId },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err?.response?.data)
    }
  },
)

export const getResultsComparisonGraph = createAsyncThunk(
  'bondTrainer/getResultsComparisonGraph',
  async ({ quizId }, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(
        `/trainer/bond-interview/results-comparison-graph`,
        { params: { bond_quiz_id: quizId } },
      )
      return res
    } catch (err) {
      throw rejectWithValue(err?.response?.data)
    }
  },
)

export const submitBondTrainerAttempt = createAsyncThunk(
  'bondTrainer/submitBondTrainerAttempt',
  async ({ isComplete }, { getState, rejectWithValue }) => {
    try {
      const state = getState().bondTrainer
      const { data } = state.bondAttemptData
      const { attempt } = data
      // Prepare question_attempts array
      const question_attempts = attempt?.quiz?.questions.map(
        (question, index) => ({
          question_id: question.id,
          chosen_answer: attempt.inputs[question?.id] || null, // Assuming question numbers start at 1
          time_spent: attempt.times_spent[question?.id] || 0,
        }),
      )

      const payload = {
        question_attempts,
        total_time_spent: Object.values(attempt.times_spent).reduce(
          (a, b) => a + b,
          0,
        ),
        is_complete: isComplete,
      }

      const res = await axiosInterceptors.post(
        `trainer/bond-interview/${attempt?.id}/submit-attempt`,
        payload,
      )
      return res
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  },
)

export const getBondInterview = createAsyncThunk(
  'bondTrainer/getBondInterview',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInterceptors.get(`/trainer/bond-interview`)
      return res
    } catch (err) {
      throw rejectWithValue(err?.response?.data)
    }
  },
)

const bondTrainerSlice = createSlice({
  name: 'bondTrainer',
  initialState: {
    bondAttemptData: { isLoading: false, data: null },
    bondAttemptError: null,
    questionIndex: 0,
    submitLoader: false,
    isAttemptCompleted: false,
    resultsComparisonGraph: { isLoading: false, data: null },
    bondInterview: { isLoading: false, data: null },
    quizIdLoading: null,
    selectedBondQuiz: null,
    isBondGraphOpen: false,
    bondQuestionList: [],
  },
  extraReducers: {
    [getBondInterview.pending]: (state) => {
      state.bondInterview.isLoading = true
      state.bondAttemptError = null
    },
    [getBondInterview.fulfilled]: (state, { payload }) => {
      state.bondInterview.isLoading = false
      state.bondInterview.data = payload?.data
    },
    [getBondInterview.rejected]: (state, { payload }) => {
      state.bondInterview.isLoading = false
      state.bondAttemptError = payload
    },
    [getResultsComparisonGraph.pending]: (state) => {
      state.resultsComparisonGraph.isLoading = true
    },
    [getResultsComparisonGraph.fulfilled]: (state, { payload }) => {
      state.resultsComparisonGraph.isLoading = false
      state.resultsComparisonGraph.data = payload?.data
    },
    [getResultsComparisonGraph.rejected]: (state) => {
      state.resultsComparisonGraph.isLoading = false
    },

    [submitBondTrainerAttempt.pending]: (state, { meta }) => {
      if (meta.arg.isComplete) {
        state.submitLoader = true
      }
    },
    [submitBondTrainerAttempt.fulfilled]: (state, { meta, payload }) => {
      state.submitLoader = false
      if (meta.arg.isComplete) {
        state.isAttemptCompleted = true
        state.bondAttemptData.data = payload?.data
      }
    },
    [submitBondTrainerAttempt.rejected]: (state) => {
      state.submitLoader = false
    },

    [startBondTrainerAttempt.pending]: (state, { meta }) => {
      if (meta.arg.quizId) {
        state.quizIdLoading = meta.arg.quizId
      }
      state.bondAttemptData.isLoading = true
    },
    [startBondTrainerAttempt.fulfilled]: (state, { payload }) => {
      state.bondAttemptData.isLoading = false
      state.bondAttemptData.data = payload.data
      state.bondQuestionList = payload?.data?.attempt?.quiz?.questions || []
    },
    [startBondTrainerAttempt.rejected]: (state, { payload }) => {
      toast.error(payload?.message, {
        duration: 3000,
        position: 'top-center',
        style: {
          backdropFilter: 'blur(4px)',
          marginTop: '20px',
          width: '350px',
        },
      })
      state.quizIdLoading = null
      state.bondAttemptData.isLoading = false
    },
  },
  reducers: {
    updateBondAttemptData: (state, { payload }) => {
      state.bondAttemptData.data = {
        attempt: payload,
        score: payload?.final_grade,
        score_out_of: payload?.final_grade_out_of,
      }
      state.bondQuestionList = payload?.quiz?.questions || []
    },
    setBondGraphOpen: (state, { payload }) => {
      state.isBondGraphOpen = payload
    },
    setSelectedBondQuiz: (state, { payload }) => {
      state.selectedBondQuiz = payload
    },
    setOngoingAttempt: (state, { payload }) => {
      const latestAttempt = state.bondInterview?.data?.quizzes?.find(
        (quiz) => quiz.id === parseInt(payload),
      )?.latest_user_attempt

      state.bondAttemptData.data = {
        attempt: latestAttempt,
      }
    },
    setIsAttemptCompleted: (state, { payload }) => {
      state.isAttemptCompleted = payload
    },
    clearBondTrainer: (state, { payload }) => {
      state.questionIndex = 0
      state.bondAttemptData = { isLoading: false, data: null }
      state.isAttemptCompleted = false
      state.quizIdLoading = null
      state.selectedBondQuiz = null
      state.bondQuestionList = []
      state.bondAttemptError = null
    },
    setQuestionIndex: (state, { payload }) => {
      state.questionIndex = payload
    },
    setAnswerForQuestion: (state, { payload }) => {
      const { questionIndex, answer } = payload

      if (state.bondAttemptData?.data?.attempt) {
        const attempt = state.bondAttemptData.data.attempt

        const questionId = attempt?.quiz?.questions[questionIndex]?.id
        if (questionId !== undefined) {
          attempt.inputs[questionId] = answer
        }
      }
    },
    incrementTimeSpent: (state, { payload }) => {
      const { questionIndex, increment } = payload

      if (state.bondAttemptData?.data?.attempt) {
        const attempt = state.bondAttemptData.data.attempt

        // Initialize the times_spent for the question if it doesn't exist
        const questionId =
          attempt?.quiz?.questions?.length > 0
            ? attempt?.quiz?.questions[questionIndex]?.id
            : null
        if (attempt.times_spent[questionId] === undefined) {
          attempt.times_spent[questionId] = 0
        }

        // Increment the time spent
        attempt.times_spent[questionId] += increment
      }
    },
  },
})

export const {
  setIsAttemptCompleted,
  setBondQuestionList,
  updateBondAttemptData,
  setBondGraphOpen,
  setSelectedBondQuiz,
  setOngoingAttempt,
  clearBondTrainer,
  setQuestionIndex,
  setAnswerForQuestion,
  incrementTimeSpent,
} = bondTrainerSlice.actions
export default bondTrainerSlice.reducer
